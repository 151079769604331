import React from "react"
import { withRouter } from "react-router-dom"
import { Knavbar } from "@/component"
import "./styles.less"

const IndexPage = ({ history }) => {
   
  return (
    <div className="rules-page">
      <Knavbar title="Rules" goBackFunction={() => history.goBack()}  />
      <p className="title">Game Rules and Guidelines</p>
      <p className="session-title">Prediction Selection: </p>
      <p className="content">Players select "BULL" (forecasting a price up) or "BEAR" (forecasting a price down) to indicate their prediction on future price trends.</p>
      <p className="session-title">Investment Placement:</p>
      <p className="content">After making a prediction, players need to invest a certain amount of funds to back their forecast of future prices.</p>
      <p className="session-title">Price Fluctuations and Sources:</p>
      <p className="content">The changes in asset prices will determine the winner. Prices are ascertained by the oracle, integrating data from multiple exchanges to ensure accuracy and real-time performance.</p>
      <p className="session-title">Winning Conditions:</p>
      <p className="content">Bull: Players who selected “UP” will win at the end of the game if the asset price is higher than it was at the time of investment.<br />Bear: Players who selected “DOWN” will win at the end of the game if the asset price is lower than it was at the time of investment.</p>
      <p className="session-title">Payouts for Assets:</p>
      <p className="content">You can view the corresponding payouts information by clicking on different investment objectives. For example, the current payout for BTC is displayed as 95%. If you win with an investment of 100 USDT, you will receive a payout of 195 USDT, consisting of a return on your investment of 100 USDT and a profit of 95 USDT.</p>
      <p className="session-title">Violation Handling:</p>
      <p className="content">Zero tolerance will be applied to violations, including but not limited to cheating, price manipulation, collusion, and other behaviors. Once any violation is detected, we will immediately suspend the account of the violator to maintain fairness in the game. If you notice any suspicious or unfair behavior, please actively report it to us. Player participation is crucial for maintaining the integrity of the game.</p>
      <p className="session-title">Risk Warning:</p>
      <p className="content">Investment and market prediction come with risks that may lead to losses. Please consider this carefully and only use funds you can afford to lose.</p>
      <br />
      <br />
      <br />
    </div>
  )
}
export default withRouter(IndexPage)